import React, { useState } from "react";
import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaNodeJs,
  FaGolang,
  FaDocker,
  FaBootstrap,
  FaLaravel,
} from "react-icons/fa6";
import {
  SiTailwindcss,
  SiExpress,
  SiPostgresql,
  SiPrisma,
  SiFigma,
  SiMysql,
  SiSwagger,
  SiPostman,
  SiNginx,
  SiTypescript,
  SiRedux,
  SiAxios,
  SiEslint,
  SiVercel,
  SiAdobepremierepro,
  SiCodeigniter,
} from "react-icons/si";

import { FaGitAlt } from "react-icons/fa";

import { DiGoogleCloudPlatform } from "react-icons/di";

import { TbBrandReactNative } from "react-icons/tb";

const skills = [
  {
    icon: <FaGolang size={40} />,
    name: "Golang",
    adress: "https://golang.org/",
  },
  {
    icon: <FaNodeJs size={40} />,
    name: "NodeJS",
    adress: "https://nodejs.org/en/",
  },
  {
    icon: <SiExpress size={40} />,
    name: "ExpressJS",
    adress: "https://expressjs.com/",
  },
  {
    icon: <FaDocker size={40} />,
    name: "Docker",
    adress: "https://www.docker.com/",
  },
  {
    icon: <SiPostgresql size={40} />,
    name: "PostgreSQL",
    adress: "https://www.postgresql.org/",
  },
  {
    icon: <SiMysql size={40} />,
    name: "MySQL",
    adress: "https://www.mysql.com/",
  },
  {
    icon: <FaReact size={40} />,
    name: "ReactJS",
    adress: "https://reactjs.org/",
  },
  {
    icon: <TbBrandReactNative size={40} />,
    name: "React Native",
    adress: "https://reactnative.dev/",
  },
  { icon: <FaGitAlt size={40} />, name: "Git", adress: "https://git-scm.com/" },
  {
    icon: <DiGoogleCloudPlatform size={40} />,
    name: "GCP",
    adress: "https://cloud.google.com/",
  },
  { icon: <SiNginx size={40} />, name: "Nginx", adress: "https://nginx.org/" },
  {
    icon: <SiSwagger size={40} />,
    name: "Swagger",
    adress: "https://swagger.io/",
  },
  {
    icon: <SiPostman size={40} />,
    name: "Postman",
    hidden: true,
    adress: "https://www.postman.com/",
  },
  {
    icon: <SiPrisma size={40} />,
    name: "Prisma ORM",
    hidden: true,
    adress: "https://www.prisma.io/",
  },
  {
    icon: <FaJs size={40} />,
    name: "JavaScript",
    hidden: true,
    adress: "https://www.javascript.com/",
  },
  {
    icon: <SiTypescript size={40} />,
    name: "Typescript",
    hidden: true,
    adress: "https://www.typescriptlang.org/",
  },
  {
    icon: <FaLaravel size={40} />,
    name: "Laravel",
    hidden: true,
    adress: "https://laravel.com/",
  },
  {
    icon: <SiCodeigniter size={40} />,
    name: "CodeIgniter",
    hidden: true,
    adress: "https://codeigniter.com/",
  },
  {
    icon: <FaHtml5 size={40} />,
    name: "HTML",
    hidden: true,
    adress: "https://www.html.com/",
  },
  {
    icon: <FaCss3 size={40} />,
    name: "CSS",
    hidden: true,
    adress: "https://www.css.com/",
  },
  {
    icon: <SiTailwindcss size={40} />,
    name: "TailwindCSS",
    hidden: true,
    adress: "https://tailwindcss.com/",
  },
  {
    icon: <FaBootstrap size={40} />,
    name: "Bootstrap 5",
    hidden: true,
    adress: "https://getbootstrap.com/",
  },
  {
    icon: <SiRedux size={40} />,
    name: "Redux",
    hidden: true,
    adress: "https://redux.js.org/",
  },
  {
    icon: <SiAxios size={40} />,
    name: "Axios",
    hidden: true,
    adress: "https://axios-http.com/",
  },
  {
    icon: <SiEslint size={40} />,
    name: "Eslint",
    hidden: true,
    adress: "https://eslint.org/",
  },
  {
    icon: <SiVercel size={40} />,
    name: "Vercel",
    hidden: true,
    adress: "https://vercel.com/",
  },
  {
    icon: <SiFigma size={40} />,
    name: "Figma",
    hidden: true,
    adress: "https://www.figma.com/",
  },
  {
    icon: <SiAdobepremierepro size={40} />,
    name: "Premiere Pro",
    hidden: true,
    adress: "https://www.adobe.com/products/premiere.html",
  },
];

const Skill = () => {
  const [showAll, setShowAll] = useState(false);
  const visibleSkills = showAll
    ? skills
    : skills.filter((skill) => !skill.hidden);

  return (
    <div
      id="skills"
      className="min-h-screen w-full mx-auto px-4 lg:flex lg:flex-col lg:items-center py-16"
    >
      <div className="text-center pb-16">
        <h2
          data-aos="fade-up"
          data-aos-offset="250"
          data-aos-duration="1000"
          className="text-2xl md:text-3xl py-2 font-bold text-[#CCD6F6]"
        >
          <span className="font-mono font-normal text-xl md:text-2xl text-[#64ffda]">
            05.
          </span>{" "}
          Skill
        </h2>
      </div>
      <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 rounded-lg px-4 lg:w-3/4">
        {visibleSkills.map((skill, index) => (
          <div
            key={index}
            data-aos="fade-up"
            data-aos-offset={skill.hidden ? "0" : "250"}
            data-aos-duration="1000"
            className="flex flex-col items-center text-[#112240] hover:text-[#64ffda] bg-[#a8b2d1] rounded-xl gap-1 py-2 cursor-pointer"
          >
            {skill.icon}
            <a
              href={skill.adress}
              target="_blank"
              className="z-30 block before:absolute before:top-0 before:bottom-0 before:right-0 before:left-0"
              rel="noreferrer"
            >
              <p className="">
                <span id="judul">{skill.name}</span>
              </p>
            </a>
          </div>
        ))}
      </div>
      <div className="text-center mt-20">
        <button
          id="show"
          className="hover:-translate-y-1 active:translate-y-0 duration-300 transition ease-in-out hover:shadow-[0_4px_0_0_#64ffda] active:shadow-[none] rounded-md py-4 px-8 font-mono text-[#64ffda] border border-solid border-[#64ffda]"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

export default Skill;
