import React, { useEffect, useState } from "react";

export default function Intro() {
  const [intro, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2100);
  }, []);
  return (
    <div>
      <div
        id="intro"
        className={
          intro
            ? "fixed w-full h-screen z-50 duration-1000 left-0 top-0 flex justify-center items-center flex-col bg-[#08101d]"
            : "fixed w-full h-screen z-50 duration-1000 left-0 top-0 flex justify-center items-center flex-col bg-[#08101d] animate-fade"
        }
      >
        <div
          id="text"
          className="text-[#CCD6F6] mb-6 text-sm md:text-base lg:text-xl tracking-wider "
        >
          <h1 className="font-mono">Welcome to my Profile!</h1>
        </div>
        <div
          id="loading"
          className="w-full h-auto flex justify-center items-center"
        >
          <div
            id="line-box"
            className="w-2/5 h-5 border-2 border-solid border-[#8892B0] rounded-3xl"
          >
            <div id="line" className="h-4 rounded-3xl bg-[#64ffda]"></div>
          </div>
        </div>
      </div>
      <div
        id="intro background"
        className="z-40 fixed w-full h-screen duration-1000 left-0 top-0 bg-[#0a192f]"
      ></div>
    </div>
  );
}
