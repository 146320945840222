import React from "react";
import "animate.css";

export default function Hero() {
  return (
    <div
      id="top-wrapper"
      className="animate__animated animate__fadeInUpBig md:py-20 h-screen"
      // Added `h-screen` class to make the height full viewport
    >
      <div
        id="container"
        className="max-w-7xl md:w-9/12 my-0 mx-6 md:mx-auto h-full flex flex-col justify-center"
        // Added `h-full` and flex utilities for vertical centering
      >
        <div>
          <h3 className="text-lg pt-40 md:pt-16 pb-2 md:pb-3 font-mono text-[#64ffda]">
            Hi, my name is
          </h3>
        </div>
        <div>
          <h1 className="text-4xl md:text-5xl lg:text-7xl py-2 md:py-3 font-bold text-[#CCD6F6]">
            Mochamad Wahyu Saputra.
          </h1>
        </div>
        <div>
          <h3 className="text-4xl md:text-5xl lg:text-7xl py-2 md:py-3 font-bold font-w text-[#a8b2d1]">
            Web Developer.
          </h3>
        </div>
        <div>
          <p className="md:text-lg lg:text-xl py-2 md:py-6 md:w-3/5 tracking-normal text-[#8892B0]">
            I'm passionate about web development and design. I have completed
            training in Front-end Web Development{" "}
            <a
              href="https://progate.com"
              className="group relative hover:text-[#64ffda] duration-500"
            >
              Progate
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
            </a>
            , IT Support google{" "}
            <a
              href="https://www.coursera.org"
              className="group relative hover:text-[#64ffda] duration-500"
            >
              Coursera
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
            </a>
            , and Back-end Javascript{" "}
            <a
              href="https://www.binaracademy.com"
              className="group relative hover:text-[#64ffda] duration-500"
            >
              Binar Academy
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
            </a>
            .
          </p>
        </div>
        <div className="mt-8 hover:-translate-y-1 active:translate-y-0 duration-300 transition ease-in-out inline-block">
          <a
            href="mailto:wahyusaputra222000@gmail.com"
            className="rounded-md hover:shadow-[0_4px_0_0_#64ffda] active:shadow-[none] py-4 px-8 font-mono duration-300 text-[#64ffda] border border-solid border-[#64ffda]"
          >
            Contact Me
          </a>
        </div>
      </div>
    </div>
  );
}
