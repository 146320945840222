import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "animate.css";
import Logo from "../img/profile.jpg";
import CV from "../pdf/CV.pdf";

export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setScroll(scrollTop > lastScrollTop);
      lastScrollTop = scrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  const handleClick = (event, targetId) => {
    event.preventDefault(); // Prevents the default action of following the link
    closeNav(); // Close the navigation dropdown when a link is clicked

    // Scroll to the element corresponding to the clicked link
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 10, // Adjust this value as needed
        behavior: "smooth", // Optional: smooth scroll behavior
      });
    }
  };

  return (
    <div
      id="header"
      className={`animate__animated animate__fadeInDownBig fixed backdrop-blur-md ${
        scroll ? "top-[-110px] w-full z-40" : "shadow-2xl top-0 w-full z-40"
      }`}
    >
      <div id="container" className="max-w-7xl my-0 mx-auto">
        <div id="header-logo">
          <div className="float-left my-6 ml-6 md:ml-8 lg:ml-6 lg:mx-0 hover:shadow-[0_0_25px_#64ffda] duration-300 cursor-pointer rounded-full">
            <img
              className="w-10 h-10 rounded-full duration-300"
              src={Logo}
              alt=""
            />
          </div>
        </div>
        <ul
          id="header-list"
          className="hidden md:flex float-right text-sm my-8 md:mr-8 md:gap-6 tracking-wide font-mono text-[#CCD6F6]"
        >
          <li>
            <a
              href="#top-wrapper"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "top-wrapper")}
            >
              <span className="text-[#64ffda]">01.</span>
              Home
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "about")}
            >
              <span className="text-[#64ffda]">02.</span>
              About
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href="#journey"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "journey")}
            >
              <span className="text-[#64ffda]">03.</span>
              Journey
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href="#project"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "project")}
            >
              <span className="text-[#64ffda]">04.</span>
              Project
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href="#skills"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "skills")}
            >
              <span className="text-[#64ffda]">05.</span>
              Skill
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="group relative duration-300"
              onClick={(e) => handleClick(e, "contact")}
            >
              <span className="text-[#64ffda]">06.</span>
              Contact
              <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
          <li>
            <a
              href={CV}
              target="_blank"
              className="rounded-md py-2 px-5 hover:shadow-[0_0_15px_#64ffda] duration-300 text-[#64ffda] border border-solid border-[#64ffda]"
              rel="noreferrer"
            >
              Resume
            </a>
          </li>
        </ul>
        <div
          id="toggle-btn"
          onClick={handleNav}
          className="block md:hidden float-right my-8 mr-6"
        >
          <FontAwesomeIcon
            icon={nav ? faTimes : faBars}
            className="cursor-pointer text-2xl text-[#64ffda]"
          />
        </div>
      </div>
      <div
        id="dropdown"
        className={`animate__animated animate__fadeInDown ${
          nav ? "md:hidden absolute left-6 right-6 top-24" : "hidden md:hidden"
        } bg-[#112240] shadow-2xl overflow-hidden rounded-2xl tracking-wide font-mono text-[#CCD6F6]`}
      >
        <div id="container" className="max-w-7xl w-auto">
          <li className="p-3 flex items-center justify-center">
            <a
              href="#top-wrapper"
              className="duration-300"
              onClick={(e) => handleClick(e, "top-wrapper")}
            >
              <span className="text-[#64ffda]">01.</span>
              Home
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href="#about"
              className="duration-300"
              onClick={(e) => handleClick(e, "about")}
            >
              <span className="text-[#64ffda]">02.</span>
              About
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href="#journey"
              className="duration-300"
              onClick={(e) => handleClick(e, "journey")}
            >
              <span className="text-[#64ffda]">03.</span>
              Journey
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href="#project"
              className="duration-300"
              onClick={(e) => handleClick(e, "project")}
            >
              <span className="text-[#64ffda]">04.</span>
              Project
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href="#skills"
              className="duration-300"
              onClick={(e) => handleClick(e, "skills")}
            >
              <span className="text-[#64ffda]">05.</span>
              Skill
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href="#contact"
              className="duration-300"
              onClick={(e) => handleClick(e, "contact")}
            >
              <span className="text-[#64ffda]">06.</span>
              Contact
            </a>
          </li>
          <li className="p-3 flex items-center justify-center">
            <a
              href={CV}
              target="_blank"
              className="rounded-md w-full flex items-center justify-center py-2 px-5 hover:shadow-[0_0_15px_#64ffda] duration-300 text-[#64ffda] border border-solid border-[#64ffda]"
              rel="noreferrer"
              onClick={(e) => handleClick(e, "resume")}
            >
              Resume
            </a>
          </li>
        </div>
      </div>
    </div>
  );
}
