import React from "react";
import 'animate.css';
import Github from '../img/github.svg';
import Instagram from '../img/instagram.svg';
import Linkedin from '../img/linkedin.svg';

export default function SocialMedia() {
    return (
        <div id="fixed">
            <div className="hidden md:block fixed w-full h-screen z-40">
                <div className="animate__animated animate__fadeInLeftBig fixed float-left bottom-0 lg:left-16 left-10">
                    <a id="github" href="https://github.com/MyWhySaputra" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 hover:-translate-y-1 duration-300" src={Github} alt=""/>
                    </a>
                    <a id="instagram" href="https://www.instagram.com/mywhysap" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 mt-6 hover:-translate-y-1 duration-300" src={Instagram} alt=""/>
                    </a>
                    <a id="linkedin" href="https://www.linkedin.com/in/mywahyusaputra/" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 mt-6 hover:-translate-y-1 duration-300" src={Linkedin} alt=""/>
                    </a>
                    <hr className="h-24 w-0 mt-8 mx-4 border border-solid border-[#a8b2d1]">
                    </hr>
                </div>
                <div className="animate__animated animate__fadeInRightBig fixed float-right bottom-0 lg:right-16 right-10">
                    <a id="gmail" href="mailto:wahyusaputra222000@gmail.com"
                        className="ml-2 text-sm font-mono text-[#a8b2d1] hover:-translate-y-1 duration-300">
                        wahyusaputra222000@gmail.com </a>
                    <hr className="h-24 w-0 mt-6 mx-4 border border-solid border-[#a8b2d1]">
                    </hr>
                </div>
            </div>
        </div>
    )
}