import React, { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Github from "../img/github.svg";
import Link from "../img/link.svg";
import ProjectMain from "./ui/ProjectMain";

export default function Project() {
  const projectBlock = [
    {
      title: "Project Internship in Binar Labs",
      description:
        "For the project Job Connect, I worked as a backend developer. I built a Restful API using Golang, integrated PostgreSQL for the database, and developed with Docker on GCP.",
      link: (
        <a
          href="https://binar-job-connect.vercel.app/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://binar-job-connect.vercel.app/",
      code1: "Golang",
      code2: "PostgreSQL",
      code3: "Docker",
    },
    {
      title: "Build Simple API Toko Retail",
      description:
        "Developed an API Toko Retail with NodeJS, ExpressJS, and PostgreSQL. It's a simple REST API with CRUD operations. The API is fully tested with Postman.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/BE_TokoRetail"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://be-retail.vercel.app/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://be-retail.vercel.app/",
      code1: "NodeJS",
      code2: "ExpressJS",
      code3: "PostgreSQL",
    },
    {
      title: "Build Simple API Bank System",
      description:
        "Developed an API Bank System with NodeJS, ExpressJS, and PostgreSQL. It's a simple REST API with CRUD operations. The API is fully tested with Postman.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/BE_Bank_System"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://bank-system-poxzy.koyeb.app/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://bank-system-poxzy.koyeb.app/",
      code1: "NodeJS",
      code2: "ExpressJS",
      code3: "PostgreSQL",
    },
  ];

  const projectHidden = [
    {
      title: "Build dashboard with multi role",
      description:
        "Developed a dashboard with ReactJS and TailwindCSS. It's a dashboard with CRUD operations.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/FE_MultiRole"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://fe-multi-role.vercel.app/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://fe-multi-role.vercel.app/",
      code1: "ReactJS",
      code2: "TailwindCSS",
    },
    {
      title: "Build example API with multi role",
      description:
        "Developed an API with NodeJS, ExpressJS, and MySQL. It's a simple REST API with CRUD operations. The API is fully tested with Postman.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/BE_MultiRole"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://be-multi-role.vercel.app/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://be-multi-role.vercel.app/",
      code1: "NodeJS",
      code2: "ExpressJS",
      code3: "MySQL",
    },
    {
      title: "A simple Portfolio using HTML, CSS and JavaScript",
      description: "I made portfolio before getting to know React.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/portfolio"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://mywhysaputra.github.io/portfolio/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://mywhysaputra.github.io/portfolio/",
      code1: "HTML",
      code2: "CSS",
      code3: "JavaScript",
    },
    {
      title: "Build a Simple Calculator",
      description:
        "A simple Calculator built with HTML, CSS, and JavaScript (ES6+).",
      github: (
        <a
          href="https://github.com/MyWhySaputra/proyek-kalkulator"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      link: (
        <a
          href="https://mywhysaputra.github.io/proyek-kalkulator/"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-6 h-6" src={Link} alt="" />
        </a>
      ),
      adress: "https://mywhysaputra.github.io/proyek-kalkulator/",
      code1: "HTML",
      code2: "CSS",
      code3: "JavaScript",
    },
    {
      title:
        "Development Expert System for Diagnosing COVID-19 Virus Disease Using Forward Chaining Method",
      description:
        "I built this project as my final project at Mercu Buana University",
      github: (
        <a
          href="https://github.com/MyWhySaputra/TA"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      adress: "https://github.com/MyWhySaputra/TA",
      code1: "CodeIgniter",
      code2: "Bootstrap",
      code3: "MySQL",
    },
    {
      title: "Library Admin Dashboard",
      description:
        "A simple Admin Library built with CodeIgniter, Bootstrap, and MySQL.",
      github: (
        <a
          href="https://github.com/MyWhySaputra/Dashboard"
          target="_blank"
          className="z-40 relative duration-300"
          rel="noreferrer"
        >
          <img className="w-5 h-5" src={Github} alt="" />
        </a>
      ),
      adress: "https://github.com/MyWhySaputra/Dashboard",
      code1: "CodeIgniter",
      code2: "Bootstrap",
      code3: "MySQL",
    },
  ];

  const [showHidden, setShowHidden] = useState(false);

  const toggleShowHidden = () => {
    setShowHidden(!showHidden);
  };

  AOS.init({
    once: true,
  });

  return (
    <div id="project" className="min-h-screen py-20">
      <div className="max-w-7xl w-9/12 mx-auto">
        <div className="text-center pb-16">
          <h2
            data-aos="fade-up"
            data-aos-offset="250"
            data-aos-duration="1000"
            className="text-2xl md:text-3xl py-2 font-bold text-[#CCD6F6]"
          >
            <span className="font-mono font-normal text-xl md:text-2xl text-[#64ffda]">
              04.
            </span>{" "}
            My Projects
          </h2>
        </div>
        <ul
          data-aos="fade-up"
          data-aos-offset="500"
          data-aos-duration="1000"
          id="list-project"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
        >
          {projectBlock.map((projectItem, index) => (
            <ProjectMain
              key={index}
              title={projectItem.title}
              description={projectItem.description}
              adress={projectItem.adress}
              code1={projectItem.code1}
              code2={projectItem.code2}
              code3={projectItem.code3}
              github={projectItem.github}
              link={projectItem.link}
            />
          ))}
          {showHidden &&
            projectHidden.map((projectItem, index) => (
              <ProjectMain
                key={index}
                title={projectItem.title}
                description={projectItem.description}
                adress={projectItem.adress}
                code1={projectItem.code1}
                code2={projectItem.code2}
                code3={projectItem.code3}
                github={projectItem.github}
                link={projectItem.link}
              />
            ))}
        </ul>
        <div className="text-center mt-20">
          <button
            className="hover:-translate-y-1 active:translate-y-0 duration-300 transition ease-in-out hover:shadow-[0_4px_0_0_#64ffda] active:shadow-[none] rounded-md py-4 px-8 font-mono text-[#64ffda] border border-solid border-[#64ffda]"
            onClick={toggleShowHidden}
          >
            {showHidden ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
    </div>
  );
}
