import React from "react";
import Github from '../img/github.svg';
import Instagram from '../img/instagram.svg';
import Linkedin from '../img/linkedin.svg';

export default function Footer() {
    return (
        <div id="footer" className="py-6">
            <div id="container" className="max-w-7xl w-3/4 md:w-1/2 my-0 mx-auto text-center">
                <div className="md:hidden flex justify-center mb-2 gap-5">
                    <a id="github" href="https://github.com/MyWhySaputra" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 hover:-translate-y-1 duration-300" src={Github} alt=""/>
                    </a>
                    <a id="instagram" href="https://www.instagram.com/mywhysap" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 hover:-translate-y-1 duration-300" src={Instagram} alt=""/>
                    </a>
                    <a id="linkedin" href="https://www.linkedin.com/in/mochamad-wahyu-saputra-90a3ab260/" target="_blank" rel="noreferrer">
                        <img className="h-6 mx-1 hover:-translate-y-1 duration-300" src={Linkedin} alt=""/>
                    </a>
                </div>
                <div>
                    <button className="font-mono md:text-lg text-sm my-2 duration-300 text-[#8892B0] cursor-pointer">build with ReactJs and Tailwind 2024</button>
                </div>
            </div>
        </div>
    )
}