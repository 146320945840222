import React from "react";
import Img from "../img/profile.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

export default function About() {
  AOS.init({
    once: true,
  });

  // CSS style untuk menyesuaikan tinggi secara otomatis
  const containerStyle = {
    minHeight: "calc(100vh - 4rem)", // Atur tinggi minimum setara dengan tinggi viewport dikurangi margin atas bawah
  };

  return (
    <div
      id="about"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1000"
      className="py-28"
      style={containerStyle} // Terapkan gaya kontainer di sini
    >
      <div
        id="container"
        className="max-w-7xl w-auto md:w-9/12 lg:w-2/3 my-0 mx-6 md:mx-auto"
      >
        <div className="right-6 overflow-hidden">
          <h2 className="text-3xl py-2 font-bold text-[#CCD6F6]">
            <span className="font-mono font-normal text-2xl text-[#64ffda]">
              02.
            </span>{" "}
            About Me
          </h2>
          <hr className="relative w-auto md:w-80 h-0 bottom-7 left-52 border border-solid border-[#a8b2d1]"></hr>
        </div>
        <div className="md:flex">
          <div>
            <p className="text-lg font-normal pt-10 pb-2 tracking-wide text-[#8892B0]">
              Hello! I'm Mochamad Wahyu Saputra, a informatics engineering
              student at Mercubuana University. I've self-taught web development
              skills in Tailwind, React, and CodeIgniter, and expanded my
              expertise through Progate.com courses. My passion lies in merging
              creativity with technology, excelling in design using Figma and
              crafting dynamic Single Page Applications with ReactJs.
            </p>
            <p className="text-lg font-normal py-2 tracking-wide text-[#8892B0]">
              Additionally, I've actively pursued skill enhancement. I've
              participated in Front-end Web Development at{" "}
              <a
                href="https://progate.com"
                className="group relative hover:text-[#64ffda] duration-500"
              >
                Progate
                <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
              </a>
              , gained IT Support insights on{" "}
              <a
                href="https://www.coursera.org"
                className="group relative hover:text-[#64ffda] duration-500"
              >
                Coursera
                <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
              </a>
              , and deepened my knowledge in Back-end Javascript at{" "}
              <a
                href="https://www.binaracademy.com"
                className="group relative hover:text-[#64ffda] duration-500"
              >
                Binar Academy
                <span className="absolute left-0 -bottom-1 h-0.5 rounded-full bg-[#64ffda] transform w-0 transition-all duration-500 group-hover:w-full"></span>
              </a>
              . This journey has empowered me to excel in various aspects of web
              development.
            </p>
          </div>
          <img
            className="mx-auto w-3/4 h-3/4 md:w-1/3 md:h-1/3 mt-12 md:ml-9 hover:shadow-[0_0_35px_#fff] cursor-pointer rounded-md duration-300"
            src={Img}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
