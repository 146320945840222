import React from "react";
import Folder from '../../img/folder.svg';
import 'animate.css';

export default function ProjectMain(props) {
    return (
        <li id="projectMain"
            className="animate__animated animate__fadeInUp relative h-auto rounded-md px-4 hover:-translate-y-2 hover:shadow-[0_0_15px_#fff] duration-300 bg-[#112240]">
            <div>
                <div className="py-8 px-3">
                    <div className="float-left">
                        <img className="w-10 h-10" src={Folder} alt=""/>
                    </div>
                    <div id="link" className="float-right flex gap-4 mt-3">
                        {props.github}
                        {props.link}
                    </div>
                </div>
                <a href={props.adress} target="_blank"
                    className="z-30 block before:absolute before:top-0 before:bottom-0 before:right-0 before:left-0" rel="noreferrer">
                    <h2 className="text-xl pt-8 px-3 font-bold text-[#CCD6F6]"><span id="judul">{props.title}</span></h2>
                </a>
                <p className="text-sm font-normal pt-4 pb-20 px-3 tracking-wide text-[#A8B2D1]">{props.description}</p>
            </div>
                <a href={props.adress} target="_blank" className="absolute bottom-8" rel="noreferrer">
                <ul className="float-left flex gap-4 pt-3 px-3 font-mono md:text-sm text-xs text-[#8892B0]">
                    <li>{props.code1}</li>
                    <li>{props.code2}</li>
                    <li>{props.code3}</li>
                </ul>
            </a>
        </li>
    )
}