import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Contact() {
    AOS.init({
        once: true,
    });
    return (
        <div id="contact" data-aos="fade-down" data-aos-offset="400" data-aos-duration="1000" className="py-28 pb-10">
            <div id="container" className="max-w-7xl md:w-3/4 lg:w-1/2 my-0 mx-6 md:mx-auto text-center">
                <div>
                    <h2 className="text-base md:text-lg pb-2 font-mono text-[#64ffda]">06. What’s Next?</h2>
                </div>
                <div>
                    <h1 className="text-4xl md:text-6xl py-2 font-bold text-[#CCD6F6]">Get In Touch</h1>
                </div>
                <div>
                    <p className="text:base md:text-lg py-2 tracking-normal text-[#8892B0]">Tell me about your next project. Feel free to Contact me, and I will get back to you as soon as possible.
                    </p>
                </div>
                <div className="my-14 hover:-translate-y-1 active:translate-y-0 duration-300 transition ease-in-out inline-block">
                    <a href="mailto:wahyusaputra222000@gmail.com"
                        className=" hover:shadow-[0_4px_0_0_#64ffda] active:shadow-[none] duration-300 transition ease-in-out rounded-md py-4 px-8 font-mono text-[#64ffda] border border-solid border-[#64ffda]">Say
                        Hello</a>
                </div>
            </div>
        </div>
    )
}