import { useEffect, useState } from "react";
import Intro from "./component/Intro";
import Navbar from "./component/Navbar";
import Hero from "./component/Hero";
import About from "./component/About";
import Project from "./component/Project";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import SocialMedia from "./component/SocialMedia";
import Journey from "./component/Journey";
import Skill from "./component/Skill";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Intro />
        </>
      ) : (
        <>
          <Navbar />
          <Hero />
          <About />
          <Journey />
          <Project />
          <Skill />
          <Contact />
          <Footer />
          <SocialMedia />
        </>
      )}
    </>
  );
}

export default App;
